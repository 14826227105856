<template>
  <!-- 资料库>新增资料 -->
  <div class="dict">
    <el-page-header
      style="margin-bottom: 1.04167vw"
      @back="goBack"
      :content="edit ? '目标列表/更新进度/新增' : '目标列表/更新进度/修改'"
    >
      <template slot="title">
          <i class="el-icon-arrow-left"></i>返回
      </template>
    </el-page-header>
    <el-form
      ref="ruleForm"
      style="width: 80%"
      :model="form"
      :rules="rules"
      :label-width="labelWidth"
    >
      <el-form-item label="园校名称：" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="所在城市：" prop="province">
        <el-cascader
          v-model="value"
          :options="city"
          @change="changeCity"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="建筑面积：" type="number" prop="buildingArea">
        <el-input v-model="form.buildingArea">
          <template slot="append">m²</template></el-input
        >
      </el-form-item>
      <el-form-item label="户外面积：" type="number" prop="outdoorArea">
        <el-input v-model="form.outdoorArea">
          <template slot="append">m²</template></el-input
        >
      </el-form-item>
      <el-form-item label="班额：" type="number" prop="targetValue">
        <el-input
          v-model="form.targetValue"
          @input="
            form.targetValue =
              form.targetValue.length === 1
                ? form.targetValue.replace(/[^1-9]/g, '')
                : form.targetValue.replace(/\D/g, '')
          "
        ></el-input>
      </el-form-item>
      <el-form-item label="开园时间：" prop="startTime">
        <el-date-picker
          v-model="form.startTime"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="签约情况：" prop="isSign">
        <el-select v-model="form.isSign" placeholder="请选择">
          <el-option label="未签约" value="0"></el-option>
          <el-option label="签约" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="管理类型：" prop="manageType">
        <el-select v-model="form.manageType" placeholder="请选择">
          <el-option label="直营" value="1"></el-option>
          <el-option label="管理直营" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="goBack">返回</el-button>
        <el-button
          type="primary"
          :disabled="uploading"
          @click="saveParkExtend('ruleForm')"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      form: {
        buildingArea: "",
        province: "", //省
        city: "", //市
        county: "", //区
        name: "",
        outdoorArea: "",
        targetValue: null,
        startTime: "",
        isSign: "",
        manageType: "",
      },
      edit: false,
      type: null,
      value: "410101000000",
      city: [
        {
          value: "410000",
          label: "河南省",
          children: [
            {
              label: "郑州市",
              value: "410101000000",
            },
          ],
        },
      ],
      uploading: false,
      directoryList: [],
      attachmentList: [],
      rules: {
        name: [{ required: true, message: "请输入园校名称", trigger: "blur" }],
        province: [{ required: true, message: "选择城市", trigger: "blur" }],
        buildingArea: [
          { required: true, message: "请输入建筑面积", trigger: "blur" },
        ],
        outdoorArea: [
          { required: true, message: "请输入户外面积", trigger: "blur" },
        ],
        targetValue: [
          { required: true, message: "请输入班额", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "请选择开园时间", trigger: "blur" },
        ],
        isSign: [
          { required: true, message: "请选择签约清空", trigger: "blur" },
        ],
        manageType: [
          { required: true, message: "请选择管理类型", trigger: "blur" },
        ],
      },
      uploadList: [],

      labelWidth: '120px',
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.query.id) {
        vm.getManageInfo(to.query.id);
      }
    });
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    window.addEventListener("resize",(e) => {
      if(e.target.innerWidth < 1300){
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
    if(window.innerWidth < 1300){
      this.labelWidth = '80px'
    } else {
      this.labelWidth = (120 / 1920 * window.innerWidth) + 'px'
    }
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    changeCity() {},
    goBack() {
      this.$router.go(-1);
    },
    getManageInfo(id) {
      let params = {
        id: id || this.id,
      };
      this.$api.getFileInfo(params).then((res) => {
        if (res.data.code == 0 && res.data.data) {
          this.form = res.data.data;
          this.edit = true;
          this.type = this.form.type;
          this.form.authority = this.form.authority.split(",");
          if (this.form.dataAttachmentList) {
            this.uploadList = this.form.dataAttachmentList;
            this.uploadList.forEach((item) => {
              item.name = item.attachmentName;
            });
          }
        } else {
          this.$message.error("该数据不存在");
          this.goBack();
        }
      });
    },

    saveParkExtend(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.uploadList.length) {
            this.$message.error("至少上传一个附件");
          } else {
            let parmas = JSON.parse(JSON.stringify(this.form));
            parmas.size = 0;
            parmas.dataAttachmentList = [];
            this.uploadList.forEach((item) => {
              let temp = {
                size: item.size,
                attachmentName: item.name.split(".")[0],
                attachmentType: item.fileType,
                url: item.url,
                type: item.type,
              };
              parmas.size += item.size;
              parmas.dataAttachmentList.push(temp);
            });
            parmas.authority = parmas.authority.join(",");
            this.$api.saveOrUpdateFileInfo(parmas).then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "保存成功",
                });
                this.goBack();
              } else {
                this.$message.error(res.data.msg || "保存失败");
              }
            });
          }
        } else {
          this.$message.error("请完善信息");
          return false;
        }
      });
    },
  },
  beforeDestroy(){
    window.removeEventListener("resize",(e) => {
      if(e.target.innerWidth < 1300){
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .tableList {
    height: calc(100% - 62px);
  }
}
</style>
